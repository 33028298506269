import { createApi } from "@reduxjs/toolkit/query/react";

import {
    ICreateUserBody,
    ICreateUserResponse,
    IDeleteUserBody,
    IDeleteUserResponse,
    IGetUserByIdQuery,
    IGetUserByIdResponse,
    IGetUsersQuery,
    IGetUsersResponse,
    ISetUserAssistantsBody,
    ISetUserAssistantsResponse,
    IUpdateUserBody,
    IUpdateUserResponse,
} from "../../types/users";
import { getUsersQueryString } from "../../utils/queries";
import { baseQueryWithIntercept } from "../connection/api";

const ENDPOINT_PREFIX = "users";

export const usersApi = createApi({
    reducerPath: "usersApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Users", "User"],
    endpoints: build => ({
        getUsers: build.query<IGetUsersResponse, IGetUsersQuery>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}?${getUsersQueryString(query)}`,
            }),
            providesTags: (result, _error, _arg) =>
                (result
                    ? [...result.users.map(({ id }) => ({ type: "Users" as const, id })), "Users"]
                    : ["Users"]),
        }),
        createUser: build.mutation<ICreateUserResponse, ICreateUserBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/`,
                method: "POST",
                body: data.data,
            }),
            invalidatesTags: ["Users"],
        }),
        addAdmin: build.mutation<ICreateUserResponse, ICreateUserBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/add-admin`,
                method: "POST",
                body: data.data,
            }),
            invalidatesTags: ["Users"],
        }),
        addManager: build.mutation<ICreateUserResponse, ICreateUserBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/add-manager`,
                method: "POST",
                body: data.data,
            }),
            invalidatesTags: ["Users"],
        }),
        addPersonnel: build.mutation<ICreateUserResponse, ICreateUserBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/add-personnel`,
                method: "POST",
                body: data.data,
            }),
            invalidatesTags: ["Users"],
        }),
        getUserById: build.query<IGetUserByIdResponse, IGetUserByIdQuery>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.userId}`,
                method: "GET",
            }),
            providesTags: (_result, _error, arg) => [{ type: "User", id: arg.userId }],
        }),
        updateUser: build.mutation<IUpdateUserResponse, IUpdateUserBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.id}`,
                method: "PUT",
                body: data.data,
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "User", id: arg.id }, "Users"],
        }),
        setUserAssistants: build.mutation<ISetUserAssistantsResponse, ISetUserAssistantsBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/set-user-assistants/${data.userId}`,
                method: "PATCH",
                body: {
                    assistantIds: data.assistantIds,
                },
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "User", id: arg.userId }, "Users"],
        }),
        deleteUser: build.mutation<IDeleteUserResponse, IDeleteUserBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "User", id: arg.id }, "Users"],
        }),
        deleteManager: build.mutation<IDeleteUserResponse, IDeleteUserBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/managers/${data.id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "User", id: arg.id }, "Users"],
        }),
        deletePersonnel: build.mutation<IDeleteUserResponse, IDeleteUserBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/personnel/${data.id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "User", id: arg.id }, "Users"],
        }),
    }),
});

export const {
    useGetUsersQuery,
    useGetUserByIdQuery,
    useCreateUserMutation,
    useAddAdminMutation,
    useAddManagerMutation,
    useAddPersonnelMutation,
    useUpdateUserMutation,
    useSetUserAssistantsMutation,
    useDeleteUserMutation,
    useDeleteManagerMutation,
    useDeletePersonnelMutation,
} = usersApi;
