import { createApi } from "@reduxjs/toolkit/query/react";

import {
    IGetAggregatedDataResponse,
    IGetAggregatedDataQuery,
} from "../../types/aggregateData";
import { getAggregatedDataQueryString } from "../../utils/queries";
import { baseQueryWithIntercept } from "../connection/api";

const ENDPOINT_PREFIX = "aggregatedData";

export const aggregatedDataApi = createApi({
    reducerPath: "aggregatedDataApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["aggregateData"],
    endpoints: build => ({
        getAggregatedData: build.query<IGetAggregatedDataResponse, IGetAggregatedDataQuery>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}?${getAggregatedDataQueryString(query)}`,
            }),
            providesTags: ["aggregateData"],
        }),

    }),
});

export const {
    useGetAggregatedDataQuery,
} = aggregatedDataApi;
