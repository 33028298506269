import { combineReducers } from "@reduxjs/toolkit";

import { aggregatedDataApi } from "./aggregatedData/api";
import { authApi } from "./auth/api";
import authReducer from "./auth/reducer";
import { clientsApi } from "./clients/api";
import { filesApi } from "./files/api";
import { inviteTokensApi } from "./inviteTokens/api";
import { organizationsApi } from "./organizations/api";
import { projectsApi } from "./projects/api";
import { usersApi } from "./users/api";

const rootReducer = combineReducers({
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [organizationsApi.reducerPath]: organizationsApi.reducer,
    [inviteTokensApi.reducerPath]: inviteTokensApi.reducer,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [aggregatedDataApi.reducerPath]: aggregatedDataApi.reducer,
    auth: authReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
