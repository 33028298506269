import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { message } from "antd";

import { getAccessToken, removeAccessToken } from "../../utils/auth";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: headers => {
        const token = getAccessToken();

        if (token !== null) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

export const baseQueryWithIntercept: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    const result = await baseQuery(args, api, extraOptions);

    if (result.error?.data) {
        if (result.error?.status === 401) {
            removeAccessToken();
            window.location.href = "/auth";
            window.location.reload();
        }
    }
    if (result.error?.data) {
        if (result.error?.status === 403) {
            const errorMessage = result.error?.data as {message: string};
            message.error(errorMessage?.message || "");
        }
    }

    return result;
};
