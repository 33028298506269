import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { aggregatedDataApi } from "./aggregatedData/api";
import { authApi } from "./auth/api";
import { clientsApi } from "./clients/api";
import { filesApi } from "./files/api";
import { inviteTokensApi } from "./inviteTokens/api";
import { organizationsApi } from "./organizations/api";
import { projectsApi } from "./projects/api";
import rootReducer from "./root-reducer";
import { usersApi } from "./users/api";

const middleWares: any = [
    authApi.middleware,
    usersApi.middleware,
    aggregatedDataApi.middleware,
    projectsApi.middleware,
    organizationsApi.middleware,
    inviteTokensApi.middleware,
    clientsApi.middleware,
    filesApi.middleware,
];

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(...middleWares),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
